exports.components = {
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-bookmarks-tsx": () => import("./../../../src/templates/bookmarks.tsx" /* webpackChunkName: "component---src-templates-bookmarks-tsx" */),
  "component---src-templates-home-search-tsx": () => import("./../../../src/templates/home-search.tsx" /* webpackChunkName: "component---src-templates-home-search-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-not-found-tsx": () => import("./../../../src/templates/not-found.tsx" /* webpackChunkName: "component---src-templates-not-found-tsx" */),
  "component---src-templates-search-tsx": () => import("./../../../src/templates/search.tsx" /* webpackChunkName: "component---src-templates-search-tsx" */),
  "component---src-templates-verb-tsx": () => import("./../../../src/templates/verb.tsx" /* webpackChunkName: "component---src-templates-verb-tsx" */),
  "component---src-templates-verbs-tsx": () => import("./../../../src/templates/verbs.tsx" /* webpackChunkName: "component---src-templates-verbs-tsx" */)
}

